// src/components/Dashboard.js
import React, { useState, useEffect } from 'react';
import { useProgress } from '../context/ProgressContext';
import { format, parseISO } from 'date-fns';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';

function Dashboard() {
  const { roadmaps } = useProgress();
  const [completedTasks, setCompletedTasks] = useState([]);
  const [uncompletedTasks, setUncompletedTasks] = useState([]);

  useEffect(() => {
    const completed = [];
    const uncompleted = [];

    Object.keys(roadmaps).forEach((roadmapName) => {
      roadmaps[roadmapName].forEach((task) => {
        if (task.completed) {
          completed.push({ ...task, roadmapName });
        } else {
          uncompleted.push({ ...task, roadmapName });
        }
      });
    });

    // Sort completed tasks by latest completion date
    completed.sort((a, b) => parseISO(b.completionDate) - parseISO(a.completionDate));

    // Sort uncompleted tasks by earliest end date
    uncompleted.sort((a, b) => parseISO(a.endDate) - parseISO(b.endDate));

    setCompletedTasks(completed);
    setUncompletedTasks(uncompleted);
  }, [roadmaps]);

  const totalTasks = completedTasks.length + uncompletedTasks.length;
  const completedPercentage = totalTasks ? Math.round((completedTasks.length / totalTasks) * 100) : 0;
  const uncompletedPercentage = totalTasks ? Math.round((uncompletedTasks.length / totalTasks) * 100) : 0;

  const data = {
    labels: ['Completed', 'Uncompleted'],
    datasets: [
      {
        data: [completedTasks.length, uncompletedTasks.length],
        backgroundColor: ['#4CAF50', '#f44336'],
        hoverBackgroundColor: ['#45a049', '#d32f2f'],
      },
    ],
  };

  return (
    <div className="dashboard-container">
      <h2 className="page-title">Dashboard</h2>
      <div className="dashboard-content">
        <div className="tasks-overview">
          <div className="task-card">
            <h3>Completed Tasks</h3>
            <ul>
              {completedTasks.slice(0, 3).map((task, index) => (
                <li key={index}>
                  <p>
                    <strong>{task.task}</strong> - {format(parseISO(task.completionDate), 'EEEE MMMM do yyyy')}
                  </p>
                  <p>Roadmap: <b>{task.roadmapName}</b></p>
                </li>
              ))}
            </ul>
          </div>
          <div className="task-card">
            <h3>Uncompleted Tasks</h3>
            <ul>
              {uncompletedTasks.slice(0, 3).map((task, index) => (
                <li key={index}>
                  <p>
                    <strong>{task.task}</strong> - Due: {format(parseISO(task.endDate), 'EEEE MMMM do yyyy')}
                  </p>
                  <p>Roadmap: <b>{task.roadmapName}</b></p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="task-summary">
          <h3>Task Summary</h3>
          <div className="pie-chart-container">
            <Pie data={data} options={{ maintainAspectRatio: false }} />
          </div>
          <div className="task-percentages">
            <p>Completed: {completedPercentage}%</p>
            <p>Uncompleted: {uncompletedPercentage}%</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
