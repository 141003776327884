import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

function Home() {
  const { currentUser } = useAuth();

  return (
    <div className="home-container">
      <div className="hero-section">
        <h1>Welcome to Your Learning Journey</h1>
        <p>Start your personalized learning path with our comprehensive roadmap creator.</p>
      </div>
      <div className="features-section">
        <h2>Features</h2>
        <div className="cards-container">
          <div className="card">
            <img src="/images/personalized-roadmaps.jpg" alt="Personalized Roadmaps" />
            <h3>Personalized Learning Roadmaps</h3>
            <p>Create customized learning roadmaps to suit your goals.</p>
          </div>
          <div className="card">
            <img src="/images/task-management.jpg" alt="Task Management" />
            <h3>Task Management</h3>
            <p>Keep track of your tasks and stay organized.</p>
          </div>
          <div className="card">
            <img src="/images/progress-tracking.jpg" alt="Progress Tracking" />
            <h3>Progress Tracking</h3>
            <p>Visualize your achievements and milestones.</p>
          </div>
        </div>
      </div>
      {!currentUser && (
        <div className="cta-section">
          <Link to="/register" className="cta-button">Sign Up</Link>
          <Link to="/login" className="cta-button">Log In</Link>
        </div>
      )}
    </div>
  );
}

export default Home;
