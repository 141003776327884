// src/components/Settings.js
import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { useProgress } from '../context/ProgressContext';
import DeleteAccountConfirmationModal from './DeleteAccountConfirmationModal';

function Settings() {
  const { currentUser, logout, deleteUser } = useAuth();
  const { deleteAllUserRoadmaps } = useProgress();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDeleteAccount = async () => {
    try {
      // Delete all user-created roadmaps
      await deleteAllUserRoadmaps(currentUser.uid);

      // Delete the user account
      await deleteUser();
    } catch (error) {
      console.error('Error deleting account: ', error);
    }
  };

  return (
    <div>
      <h2>Settings</h2>
      <button onClick={logout} className="logout-button">Logout</button><br/>
      <button onClick={() => setIsDeleteModalOpen(true)} className="delete-account-button">Delete Account</button>
      {isDeleteModalOpen && (
        <DeleteAccountConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={handleDeleteAccount}
        />
      )}
    </div>
  );
}

export default Settings;
