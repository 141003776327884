// src/components/Profile.js
import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useProgress } from '../context/ProgressContext';
import { format, parseISO } from 'date-fns';
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';

function Profile() {
  const { currentUser } = useAuth();
  const { roadmaps } = useProgress();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [completedTasks, setCompletedTasks] = useState([]);

  useEffect(() => {
    const tasks = [];
    Object.keys(roadmaps).forEach((roadmapName) => {
      roadmaps[roadmapName].forEach((task) => {
        if (task.completed) {
          tasks.push({
            ...task,
            roadmapName,
          });
        }
      });
    });

    tasks.sort((a, b) => {
      return parseISO(b.completionDate) - parseISO(a.completionDate);
    });

    setCompletedTasks(tasks.slice(0, 8));
  }, [roadmaps, currentUser]);

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    setMessage('');

    try {
      const credential = EmailAuthProvider.credential(currentUser.email, currentPassword);
      await reauthenticateWithCredential(currentUser, credential);
      await updatePassword(currentUser, newPassword);
      setMessage('Password updated successfully');
    } catch (error) {
      setMessage(error.message);
    }
  };

  return (
    <div>
      <h2>Profile Manager</h2>
      <form onSubmit={handleUpdatePassword}>
        <input
          type="password"
          placeholder="Current Password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <button type="submit">Update Password</button>
      </form>
      {message && <p>{message}</p>}
      <div className="progress-history">
        <h3>Progress History</h3>
        <ul>
          {completedTasks.map((task, index) => {
            const completionDate = task.completionDate ? format(parseISO(task.completionDate), 'EEEE MMMM do yyyy') : 'No completion date';
            return (
              <li key={index}>
                <p>
                  <strong>{task.task}</strong> - Completed on: {completionDate} (Roadmap: <b>{task.roadmapName}</b>)
                </p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default Profile;
