// src/components/Roadmap.js
import React, { useState } from 'react';
import TaskModal from './TaskModal';
import TaskDetailsModal from './TaskDetailsModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import { useProgress } from '../context/ProgressContext';
import { format, parseISO, compareAsc, compareDesc } from 'date-fns';

function Roadmap() {
  const { roadmaps, addRoadmap, updateTask, deleteTask, updateRoadmap, deleteRoadmap } = useProgress();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [newRoadmapName, setNewRoadmapName] = useState('');
  const [editTask, setEditTask] = useState(null);
  const [editRoadmap, setEditRoadmap] = useState(null);
  const [editRoadmapName, setEditRoadmapName] = useState('');
  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState('');
  const [deleteType, setDeleteType] = useState('');
  const [deleteRoadmapName, setDeleteRoadmapName] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterRoadmap, setFilterRoadmap] = useState('');
  const [filterCompleted, setFilterCompleted] = useState('');
  const [sortOption, setSortOption] = useState('');

  const handleAddRoadmap = () => {
    if (newRoadmapName.trim() !== '') {
      addRoadmap(newRoadmapName.trim());
      setNewRoadmapName('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddRoadmap();
    }
  };

  const handleTaskClick = (e, task) => {
    if (e.target.type !== 'checkbox') {
      setSelectedTask(task);
    }
  };

  const handleDeleteClick = (id, name, type, roadmapName = null) => {
    setDeleteId(id);
    setDeleteName(name);
    setDeleteType(type);
    setDeleteRoadmapName(roadmapName);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = () => {
    if (deleteType === 'task' && deleteId && deleteRoadmapName) {
      deleteTask(deleteRoadmapName, deleteId);
    } else if (deleteType === 'roadmap' && deleteName) {
      deleteRoadmap(deleteName);
    }
    setIsDeleteModalOpen(false);
  };

  const handleEditRoadmapClick = (roadmapName) => {
    setEditRoadmap(roadmapName);
    setEditRoadmapName(roadmapName);
  };

  const handleUpdateRoadmap = () => {
    if (editRoadmapName.trim() !== '') {
      updateRoadmap(editRoadmap, editRoadmapName.trim());
      setEditRoadmap(null);
      setEditRoadmapName('');
    }
  };

  const handleEditRoadmapKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleUpdateRoadmap();
    }
  };

  const handleToggleComplete = (taskId, roadmapName) => {
    const task = roadmaps[roadmapName].find(task => task.id === taskId);
    const updatedTask = {
      ...task,
      completed: !task.completed,
      completionDate: !task.completed ? new Date().toISOString() : null,
    };
    updateTask(roadmapName, updatedTask);
  };

  const filteredAndSortedRoadmaps = Object.keys(roadmaps)
    .filter(roadmapName => filterRoadmap === '' || roadmapName === filterRoadmap)
    .sort((a, b) => a.localeCompare(b));

  const renderTask = (task, roadmapName) => {
    if (searchQuery && !task.task.toLowerCase().includes(searchQuery.toLowerCase())) {
      return null;
    }

    if (filterCompleted === 'completed' && !task.completed) {
      return null;
    }

    if (filterCompleted === 'uncompleted' && task.completed) {
      return null;
    }

    const taskClass = task.completed ? 'task-completed' : '';

    return (
      <li key={task.id} onClick={(e) => handleTaskClick(e, task)} className={taskClass}>
        <input
          type="checkbox"
          checked={task.completed}
          onChange={() => handleToggleComplete(task.id, roadmapName)}
          style={{ marginRight: '10px', transform: 'scale(1.5)' }}
        />
        <span>{`${format(parseISO(task.startDate), 'EEEE MMMM do yyyy')} to ${format(parseISO(task.endDate), 'EEEE MMMM do yyyy')}: `}</span>
        <b>{task.task}</b>
        {!task.completed && (
          <>
            <button
              className="task-edit-button"
              onClick={(e) => { e.stopPropagation(); setEditTask(task); }}
            >
              Edit
            </button>
            <button
              className="task-delete-button"
              onClick={(e) => { e.stopPropagation(); handleDeleteClick(task.id, task.task, 'task', roadmapName); }}
            >
              Delete
            </button>
          </>
        )}
      </li>
    );
  };

  return (
    <div>
      <h2>Roadmaps</h2>
      <button className="add-task-button" onClick={() => setIsModalOpen(true)}>Add Task</button>
      <div className="add-roadmap-form">
        <input
          type="text"
          value={newRoadmapName}
          onChange={(e) => setNewRoadmapName(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="New Roadmap Name"
        />
        <button onClick={handleAddRoadmap}>Add Roadmap</button>
      </div>
      <div className="search-filters">
        <input
          type="text"
          placeholder="Search by task name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <select value={filterRoadmap} onChange={(e) => setFilterRoadmap(e.target.value)}>
          <option value="">All Roadmaps</option>
          {Object.keys(roadmaps).map(roadmapName => (
            <option key={roadmapName} value={roadmapName}>{roadmapName}</option>
          ))}
        </select>
        <select value={filterCompleted} onChange={(e) => setFilterCompleted(e.target.value)}>
          <option value="">All Tasks</option>
          <option value="completed">Completed</option>
          <option value="uncompleted">Uncompleted</option>
        </select>
        <select value={sortOption} onChange={(e) => setSortOption(e.target.value)}>
          <option value="">Sort By</option>
          <option value="startDateAsc">Start Date (Asc)</option>
          <option value="startDateDesc">Start Date (Desc)</option>
          <option value="endDateAsc">End Date (Asc)</option>
          <option value="endDateDesc">End Date (Desc)</option>
          <option value="nameAsc">Name (A-Z)</option>
          <option value="nameDesc">Name (Z-A)</option>
        </select>
      </div>
      <div className="roadmap-cards">
        {filteredAndSortedRoadmaps.map(roadmapName => (
          <div key={roadmapName} className="roadmap-card">
            <h3>{roadmapName}</h3>
            <div className="roadmap-buttons">
              <button
                className="roadmap-edit-button"
                onClick={() => handleEditRoadmapClick(roadmapName)}
              >
                Edit
              </button>
              <button
                className="roadmap-delete-button"
                onClick={() => handleDeleteClick(null, roadmapName, 'roadmap')}
              >
                Delete
              </button>
            </div>
            <ul>
              {roadmaps[roadmapName]
                .slice()
                .sort((a, b) => {
                  if (sortOption === 'startDateAsc') {
                    return compareAsc(parseISO(a.startDate), parseISO(b.startDate));
                  }
                  if (sortOption === 'startDateDesc') {
                    return compareDesc(parseISO(a.startDate), parseISO(b.startDate));
                  }
                  if (sortOption === 'endDateAsc') {
                    return compareAsc(parseISO(a.endDate), parseISO(b.endDate));
                  }
                  if (sortOption === 'endDateDesc') {
                    return compareDesc(parseISO(a.endDate), parseISO(b.endDate));
                  }
                  if (sortOption === 'nameAsc') {
                    return a.task.localeCompare(b.task);
                  }
                  if (sortOption === 'nameDesc') {
                    return b.task.localeCompare(a.task);
                  }
                  return 0;
                })
                .filter(task => {
                  if (searchQuery && !task.task.toLowerCase().includes(searchQuery.toLowerCase())) {
                    return false;
                  }
                  if (filterCompleted === 'completed' && !task.completed) {
                    return false;
                  }
                  if (filterCompleted === 'uncompleted' && task.completed) {
                    return false;
                  }
                  return true;
                })
                .slice(0, 6)
                .map(task => renderTask(task, roadmapName))}
            </ul>
          </div>
        ))}
      </div>
      {isModalOpen && (
        <TaskModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      {selectedTask && (
        <TaskDetailsModal
          isOpen={!!selectedTask}
          task={selectedTask}
          onClose={() => setSelectedTask(null)}
          onEdit={() => { setEditTask(selectedTask); setSelectedTask(null); }}
          onDelete={() => handleDeleteClick(selectedTask.id, selectedTask.task, 'task', Object.keys(roadmaps).find(roadmapName => roadmaps[roadmapName].some(t => t.id === selectedTask.id)))}
        />
      )}
      {editTask && (
        <TaskModal
          isOpen={!!editTask}
          onClose={() => setEditTask(null)}
          task={editTask}
          isEditMode={true}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={handleConfirmDelete}
          name={deleteName}
          type={deleteType}
        />
      )}
      {editRoadmap && (
        <div className="modal-overlay" onClick={() => setEditRoadmap(null)}>
          <div className="modal-content edit-roadmap-modal" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close" onClick={() => setEditRoadmap(null)}>
              &times;
            </button>
            <h3>Edit Roadmap</h3>
            <input
              type="text"
              value={editRoadmapName}
              onChange={(e) => setEditRoadmapName(e.target.value)}
              onKeyDown={handleEditRoadmapKeyDown}
            />
            <button onClick={handleUpdateRoadmap} className="update-roadmap-button">Update Roadmap</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Roadmap;
