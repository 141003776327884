import React, { createContext, useContext, useState, useEffect } from 'react';
import { db } from '../firebase';
import { useAuth } from './AuthContext';
import { collection, getDocs, doc, setDoc, updateDoc, deleteDoc, getDoc } from 'firebase/firestore';
import CryptoJS from 'crypto-js';

const ProgressContext = createContext("");

export const useProgress = () => {
  return useContext(ProgressContext);
};

export const ProgressProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [roadmaps, setRoadmaps] = useState({});

  const secretKey = process.env.REACT_APP_SECRET_KEY;

  const encryptData = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
  };

  const decryptData = (ciphertext) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  };

  useEffect(() => {
    if (currentUser) {
      fetchRoadmaps(currentUser.uid);
    } else {
      setRoadmaps({});
    }
  }, [currentUser]);

  const fetchRoadmaps = async (userId) => {
    try {
      const roadmapsRef = collection(db, 'users', userId, 'roadmaps');
      const roadmapsSnapshot = await getDocs(roadmapsRef);
      const fetchedRoadmaps = {};
      roadmapsSnapshot.forEach((doc) => {
        const data = decryptData(doc.data().encryptedData);
        fetchedRoadmaps[doc.id] = data.tasks || [];
      });
      
      setRoadmaps(fetchedRoadmaps);
    } catch (error) {
      console.error("Error fetching roadmaps: ", error);
    }
  };

  const addRoadmap = async (roadmapName) => {
    const userId = currentUser.uid;
    const newRoadmap = { tasks: [] };
    const encryptedData = encryptData(newRoadmap);
    await setDoc(doc(db, 'users', userId, 'roadmaps', roadmapName), { encryptedData });
    setRoadmaps((prevRoadmaps) => ({
      ...prevRoadmaps,
      [roadmapName]: [],
    }));
  };

  const updateRoadmap = async (oldRoadmapName, newRoadmapName) => {
    const userId = currentUser.uid;
    const roadmapRef = doc(db, 'users', userId, 'roadmaps', oldRoadmapName);
    const roadmapData = decryptData((await getDoc(roadmapRef)).data().encryptedData);
    const encryptedData = encryptData(roadmapData);
    await setDoc(doc(db, 'users', userId, 'roadmaps', newRoadmapName), { encryptedData });
    await deleteDoc(roadmapRef);
    setRoadmaps((prevRoadmaps) => {
      const updatedRoadmaps = { ...prevRoadmaps };
      updatedRoadmaps[newRoadmapName] = updatedRoadmaps[oldRoadmapName];
      delete updatedRoadmaps[oldRoadmapName];
      return updatedRoadmaps;
    });
  };

  const deleteRoadmap = async (roadmapName) => {
    const userId = currentUser.uid;
    await deleteDoc(doc(db, 'users', userId, 'roadmaps', roadmapName));
    setRoadmaps((prevRoadmaps) => {
      const updatedRoadmaps = { ...prevRoadmaps };
      delete updatedRoadmaps[roadmapName];
      return updatedRoadmaps;
    });
  };

  const addTask = async (roadmapName, task) => {
    const userId = currentUser.uid;
    const updatedTasks = [...roadmaps[roadmapName], task];
    const encryptedData = encryptData({ tasks: updatedTasks });
    await updateDoc(doc(db, 'users', userId, 'roadmaps', roadmapName), { encryptedData });
    setRoadmaps((prevRoadmaps) => ({
      ...prevRoadmaps,
      [roadmapName]: updatedTasks,
    }));
  };

  const updateTask = async (roadmapName, updatedTask) => {
    const userId = currentUser.uid;
    const updatedTasks = roadmaps[roadmapName].map((task) =>
      task.id === updatedTask.id ? updatedTask : task
    );
    const encryptedData = encryptData({ tasks: updatedTasks });
    await updateDoc(doc(db, 'users', userId, 'roadmaps', roadmapName), { encryptedData });
    setRoadmaps((prevRoadmaps) => ({
      ...prevRoadmaps,
      [roadmapName]: updatedTasks,
    }));
  };

  const deleteTask = async (roadmapName, taskId) => {
    const userId = currentUser.uid;
    const updatedTasks = roadmaps[roadmapName].filter((task) => task.id !== taskId);
    const encryptedData = encryptData({ tasks: updatedTasks });
    await updateDoc(doc(db, 'users', userId, 'roadmaps', roadmapName), { encryptedData });
    setRoadmaps((prevRoadmaps) => ({
      ...prevRoadmaps,
      [roadmapName]: updatedTasks,
    }));
  };

  const deleteAllUserRoadmaps = async (userId) => {
    try {
      const roadmapsRef = collection(db, 'users', userId, 'roadmaps');
      const roadmapsSnapshot = await getDocs(roadmapsRef);
      const deletePromises = [];
      roadmapsSnapshot.forEach((doc) => {
        deletePromises.push(deleteDoc(doc.ref));
      });
      await Promise.all(deletePromises);
    } catch (error) {
      console.error("Error deleting roadmaps: ", error);
    }
  };

  return (
    <ProgressContext.Provider
      value={{
        roadmaps,
        addRoadmap,
        addTask,
        updateTask,
        deleteTask,
        updateRoadmap,
        deleteRoadmap,
        deleteAllUserRoadmaps
      }}
    >
      {children}
    </ProgressContext.Provider>
  );
};
