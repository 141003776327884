// src/components/TaskModal.js
import React, { useState, useEffect } from 'react';
import { useProgress } from '../context/ProgressContext';
import { v4 as uuidv4 } from 'uuid';
import { format, parseISO } from 'date-fns';

function TaskModal({ isOpen, onClose, task = null, isEditMode = false }) {
  const { roadmaps, addTask, updateTask } = useProgress();
  const [taskName, setTaskName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [resources, setResources] = useState('');
  const [selectedRoadmap, setSelectedRoadmap] = useState('');

  useEffect(() => {
    if (task && isEditMode) {
      setTaskName(task.task);
      setStartDate(format(parseISO(task.startDate), 'yyyy-MM-dd'));
      setEndDate(format(parseISO(task.endDate), 'yyyy-MM-dd'));
      setResources(task.resources);
      setSelectedRoadmap(Object.keys(roadmaps).find(roadmapName => roadmaps[roadmapName].some(t => t.id === task.id)));
    }
  }, [task, isEditMode, roadmaps]);

  const today = format(new Date(), 'yyyy-MM-dd');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (taskName.trim() !== '' && startDate !== '' && endDate !== '' && resources.trim() !== '' && selectedRoadmap !== '') {
      const newTask = {
        id: isEditMode ? task.id : uuidv4(),
        task: taskName.trim(),
        startDate,
        endDate,
        resources,
        completed: isEditMode ? task.completed : false,
        completionDate: isEditMode ? task.completionDate : null,
      };
      if (isEditMode) {
        updateTask(selectedRoadmap, newTask);
      } else {
        addTask(selectedRoadmap, newTask);
      }
      onClose();
    }
  };

  return (
    isOpen && (
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <button className="modal-close" onClick={onClose}>
            &times;
          </button>
          <h3>{isEditMode ? 'Edit Task' : 'Add Task'}</h3>
          <form onSubmit={handleSubmit} className="task-form">
            <div className="form-group">
              <label htmlFor="roadmap">Select Roadmap:</label>
              <select
                id="roadmap"
                value={selectedRoadmap}
                onChange={(e) => setSelectedRoadmap(e.target.value)}
                required
              >
                <option value="">Select Roadmap</option>
                {Object.keys(roadmaps).map(roadmapName => (
                  <option key={roadmapName} value={roadmapName}>
                    {roadmapName}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="task">Task:</label>
              <input
                type="text"
                id="task"
                value={taskName}
                onChange={(e) => setTaskName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="startDate">Start Date:</label>
              <input
                type="date"
                id="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                min={today} // Restrict start date to today and future
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="endDate">End Date:</label>
              <input
                type="date"
                id="endDate"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                min={startDate || today} // Restrict end date to the start date or later
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="resources">Resources:</label>
              <textarea
                id="resources"
                value={resources}
                onChange={(e) => setResources(e.target.value)}
                required
                className="wider-input"
              />
            </div>
            <button type="submit">{isEditMode ? 'Update Task' : 'Add Task'}</button>
          </form>
        </div>
      </div>
    )
  );
}

export default TaskModal;
