// src/components/DeleteAccountConfirmationModal.js
import React from 'react';

function DeleteAccountConfirmationModal({ isOpen, onClose, onConfirm }) {
  return (
    isOpen && (
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <button className="modal-close" onClick={onClose}>
            &times;
          </button>
          <h3>Confirm Account Deletion</h3>
          <p>Are you sure you want to delete your account? This action cannot be undone. All your roadmaps and tasks will be deleted.</p>
          <button onClick={onConfirm} className="confirm-delete-button">Delete Account</button>
          <button onClick={onClose} className="cancel-delete-button">Cancel</button>
        </div>
      </div>
    )
  );
}

export default DeleteAccountConfirmationModal;
