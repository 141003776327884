// src/components/DeleteConfirmationModal.js
import React from 'react';

function DeleteConfirmationModal({ isOpen, onClose, onConfirm, name, type }) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        <h3>Confirm Deletion</h3>
        <p>Are you sure you want to delete this {type}? This action cannot be undone.</p>
        <p><strong>{name}</strong></p>
        <button onClick={onConfirm} className="confirm-delete-button">Yes, delete</button>
        <button onClick={onClose} className="cancel-delete-button">Cancel</button>
      </div>
    </div>
  );
}

export default DeleteConfirmationModal;
