// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Register from './components/Register';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Roadmap from './components/Roadmap';
import Settings from './components/Settings';
import ForgotPassword from './components/ForgotPassword';
import Profile from './components/Profile';
import PrivateRoute from './components/PrivateRoute';
import Home from './components/Home';
import About from './components/About';
import { AuthProvider, useAuth } from './context/AuthContext';
import { ProgressProvider } from './context/ProgressContext';
import './App.css';

function Navbar() {
  const { currentUser } = useAuth();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav>
      <div className="navbar-brand">
      <Link to="/" className="navbar-home-link">
          <img src="/images/logo.jpg" alt="App Logo" className="navbar-logo" />
          <span className="navbar-title">EduCompass</span> {/* Use your chosen name here */}
        </Link>
      </div>
      <div className="navbar-toggle" onClick={toggleMobileMenu}>
        &#9776;
      </div>
      <ul className={`${isMobileMenuOpen ? 'navbar-links-mobile' : ''}`}>
        <li>
          <Link to="/about">About</Link>
        </li>
        {currentUser ? (
          <>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <Link to="/roadmap">Roadmap</Link>
            </li>
            <li>
              <Link to="/profile">Profile</Link>
            </li>
            <li>
              <Link to="/settings">Settings</Link>
            </li>
          </>
        ) : (
          <>
            <li>
              <Link to="/register">Register</Link>
            </li>
            <li>
              <Link to="/login">Login</Link>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
}

function App() {
  return (
    <AuthProvider>
      <ProgressProvider>
        <Router>
          <div className="App">
            <Navbar />
            <div className="content">
              <Routes>
                <Route path="/about" element={<About />} />
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route
                  path="/dashboard"
                  element={
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/roadmap"
                  element={
                    <PrivateRoute>
                      <Roadmap />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <PrivateRoute>
                      <Profile />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <PrivateRoute>
                      <Settings />
                    </PrivateRoute>
                  }
                />
                <Route path="/" element={<Home />} />
              </Routes>
            </div>
          </div>
        </Router>
      </ProgressProvider>
    </AuthProvider>
  );
}

export default App;
