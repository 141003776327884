// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebase';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  deleteUser as firebaseDeleteUser,
  reauthenticateWithCredential,
  EmailAuthProvider,
  onAuthStateChanged
} from 'firebase/auth';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const signup = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const login = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const logout = () => {
    return signOut(auth);
  };

  const deleteUser = async () => {
    if (!currentUser) return;

    // Reauthenticate the user before deleting
    const credential = EmailAuthProvider.credential(currentUser.email, prompt('Please enter your password again:'));
    await reauthenticateWithCredential(currentUser, credential);

    // Delete the user
    await firebaseDeleteUser(currentUser);
  };

  const value = {
    currentUser,
    signup,
    login,
    logout,
    deleteUser
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}
