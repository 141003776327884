import React from 'react';

function About() {
  return (
    <div className="about-container">
      <div className="hero-section">
        <h1>About Us</h1>
        <p>We provide a versatile platform for creating and managing personalized learning roadmaps.</p>
      </div>
      <div className="mission-section">
        <h2>Our Mission</h2>
        <p>We believe that everyone has the potential to master new skills and knowledge. Our platform is designed to offer structured learning paths, practical tasks, and community support to help you achieve your goals.</p>
      </div>
      <div className="features-section">
        <h2>Features and Benefits</h2>
        <div className="cards-container">
          <div className="card">
            <img src="/images/customized-roadmaps.jpg" alt="Customized Roadmaps" />
            <h3>Customized Roadmaps</h3>
            <p>Tailored to fit your learning pace and goals.</p>
          </div>
          <div className="card">
            <img src="/images/task-management.jpg" alt="Task Management" />
            <h3>Task Management</h3>
            <p>Keep track of your progress and stay organized.</p>
          </div>
          <div className="card">
            <img src="/images/progress-tracking.jpg" alt="Progress Tracking" />
            <h3>Progress Tracking</h3>
            <p>Visualize your achievements and milestones.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
