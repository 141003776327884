// src/components/TaskDetailsModal.js
import React from 'react';
import { format, parseISO } from 'date-fns';

function TaskDetailsModal({ isOpen, onClose, task, onEdit, onDelete }) {
  if (!isOpen) return null;

  const formattedStartDate = format(parseISO(task.startDate), 'EEEE MMMM do yyyy');
  const formattedEndDate = format(parseISO(task.endDate), 'EEEE MMMM do yyyy');

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        <h3>Task Details</h3>
        <p><strong>Task:</strong> {task.task}</p>
        <p><strong>Start Date:</strong> {formattedStartDate}</p>
        <p><strong>End Date:</strong> {formattedEndDate}</p>
        <p><strong>Resources:</strong> {task.resources}</p>
        <button className="task-edit-button" onClick={onEdit}>Edit</button>
        <button className="task-delete-button" onClick={onDelete}>Delete</button>
      </div>
    </div>
  );
}

export default TaskDetailsModal;
